import { mapGetters } from "vuex";
import { compact } from "lodash";

export default {
    name: "professional-club",
    computed: {
        ...mapGetters({
            clientUserData: "profile/clientUserData",
            isProfessional: "profile/isProfessional",
            user: "profile/user",
        }),
    },
    methods: {
        compact,
    },
};
